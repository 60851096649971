import axios, { CancelTokenSource } from 'axios'
import { useState, useRef, useEffect, useCallback } from 'react'
import { generativeTextService } from 'api'
import { isRight } from 'fp-ts/lib/These'
import { CenteredLoader } from 'components/Loader/Loader'
import Select from 'components/Select/SelectV2'
import { isArray } from 'lodash'
import { getKnowledgeSourceCardTitle } from 'page/knowledge-base/KnowledgeSource/KnowledgeSourcesCard'

export const MAX_KNOWLEDGE_SOURCES = 10

export const KnowledgeSourceSelect = ({
  onChange,
  value,
  validationError,
}: {
  onChange: (selectedSources: number[]) => void
  value: number[]
  validationError?: string
}) => {
  const cancelTokenRef = useRef<CancelTokenSource | null>(null)
  const [sourceOptions, setSourceOptions] = useState<
    { value: number; label: string }[]
  >([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchKnowledgeSources = useCallback(async () => {
    setLoading(true)
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel()
    }
    cancelTokenRef.current = axios.CancelToken.source()

    const response = await generativeTextService.scrape.knowledgeSources.list({
      cancelToken: cancelTokenRef.current.token,
      statuses: ['SUCCESS'],
    })

    if (isRight(response)) {
      setSourceOptions(
        response.right?.results?.map(source => ({
          value: source.id,
          label: source.title || getKnowledgeSourceCardTitle(source),
        }))
      )
      setLoading(false)
      return
    }

    if (
      response.left.kind === 'cancel' ||
      (response.left.kind === 'http' &&
        response.left.http.code === 'ERR_CANCELED')
    ) {
      return
    }
    setLoading(false)
    setError(true)
  }, [cancelTokenRef, setSourceOptions])

  useEffect(() => {
    fetchKnowledgeSources()
  }, [fetchKnowledgeSources])

  if (loading) {
    return (
      <div className="d-flex flex-row align-center height-50px">
        <CenteredLoader className="w-100 " />
      </div>
    )
  }
  if (error) {
    return (
      <div className="d-flex flex-row align-center height-250px">
        Failed to load the knowledge sources
      </div>
    )
  }

  const filterSources = (option: { label: string }, query: string) => {
    return option.label.toLowerCase().includes(query.toLowerCase())
  }

  return (
    <div>
      {sourceOptions && (
        <>
          <Select<{ label: string; value: number }>
            options={sourceOptions}
            menuPlacement="bottom"
            className="h-100"
            placeholder="Restrict search to..."
            explicitPreventOverlap={true}
            defaultValue={sourceOptions.filter(
              option => value && value.includes(option.value)
            )}
            filterOption={filterSources}
            onChange={values => {
              if (isArray(values)) {
                onChange(values.map(value => value.value))
              }
            }}
            isMulti
          />
          {validationError && (
            <div className="text-danger mt-2 fs-small">{validationError}</div>
          )}
        </>
      )}
    </div>
  )
}
